import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./styles.css";
import axios from "axios";
import URL from "../Common/confile/appConfig.json";

const notificationDashbord = ({ match }) => {
  // export default function notificationDashbord () {
  const [msg, setMsg] = useState("");
  const [heading, setHeading] = useState("");

  const [expoPushToken, setExpoPushToken] = useState();
  // let { myKey } = useParams();

  var myKey = match.params.myKey;

  useEffect(() => {
    axios
      .get(`${URL.IP}/fetchToken/`, {
        params: {
          myKey: myKey,
        },
      })
      .then(function(response) {
        console.log("responsefetchtoken  :" + response);

        let sendToken = [];
        for (let i = 0; i < response.data.length; i++) {
          sendToken[i] = response.data[i].token;
        }
        setExpoPushToken(sendToken);
        //  token[];
        //   let allSubCustToken = response.data.token;
        // alert("Message Succesfully Added");
      });
  }, []);

  const handlesubmit = async () => {
    //  try {
    const body = {
      title: heading,
      message: msg,
      custCd: match.params.myKey,
      date: new Date().toISOString(),
    };

    axios.post(`${URL.IP}/message`, body).then(function(response) {
      alert("Message Succesfully Added");
    });

    sendPushNotification(expoPushToken);
  };

  async function sendPushNotification(expoPushToken) {
    const message = {
      to: expoPushToken,
      sound: "default",
      title: heading,
      body: msg,
    };

    await fetch("https://exp.host/--/api/v2/push/send", {
      mode: "no-cors",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Accept-encoding": "gzip, deflate",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(message),
    });
  }

  return (
    <div>
      <div className="col-lg-9 col-md-9 col-sm-6 col-xs-12">
        <div class="notify">Send Notification</div>
        <div>
          <div class="text">
            <textarea
              rows={0}
              className="col-lg-12 col-md-12 col-sm-6 col-xs-12"
              placeholder="title..."
              name="message"
              value={heading}
              onChange={(e) => setHeading(e.target.value)}
            />
            &nbsp;&nbsp;
            <textarea
              rows={8}
              className="col-lg-12 col-md-12 col-sm-6 col-xs-12"
              placeholder="typ message..."
              name="message"
              value={msg}
              onChange={(e) => setMsg(e.target.value)}
            />
            <div>
              <button className="loginBtn" onClick={handlesubmit}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default notificationDashbord;
